// Translated
// Migrated
<template lang="pug">
.m-auto.my-5.py-5
  .py-3.d-flex.flex-column.justify-content-center.align-items-center
    fa-layers.fa-5x.fa-fw.mb-5.pb-5
      fa(
        :icon="icons.xmark",
        transform="up-2 left-3",
        style="--fa-secondary-color: #004b9c"
      )
      fa#magGlass(
        :icon="icons.magGlass",
        transform="grow-11",
        opacity,
        style="--fa-secondary-color: #004b9c"
      )
    slot
</template>

<script>
import { faMagnifyingGlass, faTimes } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  data () {
    return {
      icons: {
        magGlass: faMagnifyingGlass,
        xmark: faTimes,
      },
    }
  },
})
</script>

<style lang="scss" scoped>
#magGlass {
  left: -4px;
  top: 2px;

  &.svg-inline--fa .fa-secondary{
    opacity: 1
  }
}
</style>
