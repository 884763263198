// Translated
// Migrated
<template lang="pug">
AuthGuard.favourites-container.main-content.mt-5
  .row
    NotFound.col-12(v-if="favourites.length === 0" data-i18n="charterNoFavouritesYet") {{ $t('charterNoFavouritesYet') }}
    .col-12.col-md-6(v-for="fav in favourites" :key="`favourite-${fav.id}`")
      Trip(:trip="fav" :loading="!fav.url")
</template>

<script setup>
definePageMeta({
  middleware: 'auth',
})

const userStore = useUserStore()
const localeStore = useLocaleStore()

const { favourites } = storeToRefs(userStore)

await useAsyncData(async () => {
  await userStore.listFavourites()
  await userStore.populateFavourites()

  localeStore.SET_PAGE_WIDE_TRANSLATIONS(createPageWideTranslation(['favouritesTitle']))
})
</script>
