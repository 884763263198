// Translated
// Migrated
<template lang="pug">
HorizontalCardWithImage(
  :link="booking?.url"
  :title="booking?.tripname"
  :subtitle="trip?.subtitle"
  :image="image"
  :image-type="imageType"
  :parent-class="['my-3 mt-lg-0']"
)
  template(#description)
    .tripresult__preamble(v-if="content") {{ content }}
  template(#bottomLeft)
    .tripresult__details(:class="$isSol ? 'mt-3' : 'mt-5'")
      nuxt-link.btn.btn-orange.mr-4(
        v-if="!isPast"
        :to="booking?.url"
        data-i18n="mybookingSubtitle"
      ) {{ $t('mybookingSubtitle') }}
      .tripresult__details__item(
        v-for="{ type, value } in details"
        :key="type"
      )
        StarByLocale.mr-1(
          v-if="type === 'reviews'"
          :size="16"
        )
        fa.mr-1(
          v-else-if="type === 'date'"
          :icon="icons.calendar"
        )
        | {{ value }}
  template(#labels)
    .d-inline-block
      span.mx-1 {{ booking?.tripdate }}
      fa.mr-1(:icon="icons.calendar")
</template>

<script>
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'
import DaysMixin from '@layers/web/mixins/days.mixin'

export default defineNuxtComponent({
  setup () {
    const { public: { images: imagesConfig } } = useRuntimeConfig()

    return {
      imagesConfig,
    }
  },

  mixins: [ DaysMixin ],

  props: {
    trip: {
      type: Object,
      default: null,
    },

    booking: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      icons: {
        calendar: faCalendar,
      },
    }
  },

  computed: {
    image () {
      if (!this.trip) {
        return this.imagesConfig.fallbackImage
      }
      if (this.trip?.image_cloudinary) {
        return this.trip.image_cloudinary
      }

      if (this.trip?.main_picture_cloudinary) {
        return this.trip.main_picture_cloudinary
      }

      if (this.trip?.image) {
        return this.trip.image.replace(`${this.imageType}:`, '')
      }

      // TODO: Check if we can use just image from above ^
      if (this.trip?.picture) {
        return this.trip.picture
      }
      return this.imagesConfig.fallbackImage
    },

    isPast () {
      return this.$dayjs(this.booking?.tripdate)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59) < this.$dayjs()
    },

    imageType () {
      if (!this.trip) {
        return 'url'
      }
      return this.trip?.image_type || 'cloudinary'
    },

    content () {
      if (!this.trip) {
        return null
      }
      return this.trip.preamble || this.trip.short_preamble || truncateByWords(this.trip.description, 50)
    },

    details () {
      const details = []
      if (this.booking?.tripdate) {
        details.push({ type: 'date', value: this.booking.tripdate })
      }
      if (this.trip?.days_min || this.trip?.days) {
        details.push({ type: 'days', value: this.$t('daysText', this.days(this.trip)) })
      }
      if (this.trip?.destination) {
        details.push({ type: 'destination', value: [this.trip.destination, this.trip?.country].filter(Boolean).join(', ') })
      }
      return details
    },
  },
})
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/product/_product-trip-result";

.trip-result-label__discount {
  position: relative;

  .small {
    font-size: .7em;
  }
  & > div {
    padding-top: .65em;
  }
  &__past {
    position: absolute;
    right: 0;
    top: 0; // -.25rem;
  }
}
</style>
